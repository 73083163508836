<template>
  <div class="contact-info">
    <h3 class="contact-info__title">
      Контактная информация
    </h3>
    <div class="contact-info-content">
      <div class="contact-info__item">
        <div class="contact-info-icon-container">
          <Icon type="phone" />
        </div>
        <dl>
          <dt>Телефон</dt>
          <dd>
            <a href="tel:+74952666479">+7 495 266-64-79</a>
          </dd>
        </dl>
      </div>
      <div class="contact-info__item">
        <div class="contact-info-icon-container">
          <Icon type="mail" />
        </div>
        <dl>
          <dt>Эл. почта</dt>
          <dd>
            <a href="mailto:support@finny.ru">
              support@finny.ru
            </a>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactInfo",
  data() {
    return {};
  },
};
</script>

<style src="./contact-info.scss" lang="scss"></style>
